<!-- eslint-disable tailwindcss/no-custom-classname -->
<template>
    <div class="relative size-full"
        :class="{
            'aspect-[3/2]': cropImage,
        }">
        <template v-if="blok.product_links && blok.product_links.length > 0">
            <template v-for="marker in blok.product_links"
                :key="marker.id">
                <sb-product-image-lift-marker v-if="marker.product?.source"
                    :marker="marker"
                    :products="products"
                    class="absolute left-[var(--marker-position-x)] top-[var(--marker-position-y)]"
                    :style="{
                        '--marker-position-x': `${marker.position_x}%`,
                        '--marker-position-y': `${marker.position_y}%`
                    }" />
            </template>
        </template>

        <!-- Single image -->
        <template v-if="blok.image && !blok.enable_slideshow">
            <!-- Product image with the links to the room. -->
            <nuxt-link v-if="blok.link_to_room.cached_url"
                :to="formatSbUrl(blok.link_to_room.cached_url)"
                :aria-label="blok.title"
                :title="blok.image.alt"
                @click="addPromotionClick(promo)">
                <nuxt-picture :src="blok.image.filename"
                    class="aspect-[3/2]"
                    width="760"
                    height="507"
                    provider="storyblok"
                    fit="in"
                    :preload="{ fetchPriority: 'low' }"
                    sizes="sm:100vw md:768px lg:504px xl:632px 2xl:760px"
                    :img-attrs="{
                        class: 'object-cover w-full h-full'
                    }"
                    :alt="blok.image.alt ?? blok.title" />
            </nuxt-link>

            <!-- Product image without the links to the room. -->
            <nuxt-picture v-else
                :src="blok.image.filename"
                class="aspect-[3/2]"
                width="760"
                height="507"
                provider="storyblok"
                fit="in"
                :preload="{ fetchPriority: 'low' }"
                sizes="sm:100vw md:768px lg:504px xl:632px 2xl:760px"
                :img-attrs="{
                    class: 'object-cover w-full h-full'
                }"
                :alt="blok.image.alt ?? blok.title" />
        </template>

        <!-- Carousel -->
        <template v-if="blok.images && blok.enable_slideshow">
            <div class="relative">
                <v-auto-scroller :items="blok.images">
                    <template #item="{ filename, alt, title }">
                        <nuxt-link v-if="blok.link_to_room.cached_url.length > 0"
                            :to="formatSbUrl(blok.link_to_room.cached_url)"
                            :aria-label="blok.title"
                            :title="blok.image.alt"
                            @click="addPromotionClick(promo)">
                            <nuxt-picture :src="filename"
                                class="aspect-[3/2]"
                                width="760"
                                height="507"
                                provider="storyblok"
                                fit="in"
                                :preload="{ fetchPriority: 'low' }"
                                sizes="sm:100vw md:768px lg:504px xl:632px 2xl:760px"
                                :img-attrs="{
                                    class: 'object-cover size-full'
                                }"
                                :alt="alt ?? title" />
                        </nuxt-link>

                        <template v-else>
                            <nuxt-picture :src="filename"
                                class="aspect-[3/2]"
                                width="760"
                                height="507"
                                provider="storyblok"
                                fit="in"
                                :preload="{ fetchPriority: 'low' }"
                                sizes="sm:100vw md:768px lg:504px xl:632px 2xl:760px"
                                :img-attrs="{
                                    class: 'object-cover size-full'
                                }"
                                :alt="alt ?? title" />
                        </template>
                    </template>
                </v-auto-scroller>
            </div>
        </template>

        <!-- Text content. -->
        <div v-if="!!blok.title || !!blok.cta_text"
            class="pointer-events-none absolute left-1/2 top-1/2 z-[9] w-full
            -translate-x-1/2 -translate-y-1/2 space-y-md p-xl text-center">
            <div v-if="blok.title">
                <p class="text-5xl font-bold uppercase text-[color:var(--text-color)]
                    md:text-8xl lg:text-7xl xl:text-9xl"
                    :class="{
                        'custom-shadow': blok.enable_text_shadow,
                    }"
                    :style="{
                        '--text-color': titleTextColor
                    }">
                    {{ blok.title }}
                </p>
            </div>

            <v-button v-if="blok.cta_text"
                :theme="ctaButtonColor"
                size="base">
                {{ blok.cta_text }}
            </v-button>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { BlokComponent, ButtonThemes } from '~~/types';
import type {
    ViewMarkerProductsQuery,
} from '~~/graphql';

const properties = defineProps<{
    blok: Extract<BlokComponent, { component: 'sb-product-image-lift' }>
    id?: string | number
    name?: string | null
    creative?: string | null
    page?: string
    type?: string
    products: ViewMarkerProductsQuery['products']['edges'][0]['node'][]
}>();

const route = useRoute();
const { format: formatSbUrl } = useSbUrl();
const { addPromotionClick } = usePromotion();

function convertString(string: string) {
    const words = string.split('-');
    const convertedString = words.map(((word) => word.charAt(0).toUpperCase() + word.slice(1))).join(' ');
    return convertedString;
}

/** Title text color. */
const titleTextColor = computed<string>(() => (
    properties.blok.text_color.color ?? '#FFF'
));

/** CTA button color property.
 *  We can cast the color from Storyblok to button component theme.
 *  If the color is not allowed, we will default to white.
 */
const ctaButtonColor = computed<ButtonThemes>(() => {
    const allowedColors: ButtonThemes[] = ['black', 'white'];
    return allowedColors.includes(properties.blok.cta_background_color as ButtonThemes)
        ? properties.blok.cta_background_color as ButtonThemes
        : 'white';
});

const promo = computed(() => ({
    /* eslint-disable @typescript-eslint/naming-convention */
    creative_name: convertString(properties.blok.component),
    creative_slot: properties.blok.component, // component name
    // eslint-disable-next-line no-underscore-dangle
    promotion_id: properties.blok._uid,
    promotion_name: properties.blok.title,
    source_url: route.fullPath,
    target_url: properties.blok.link_to_room.cached_url,
    /* eslint-enable @typescript-eslint/naming-convention */
}));

const cropImage = computed(() => properties.blok.use_autocrop ?? false);
</script>

<style scoped>
.custom-shadow {
    text-shadow: 2.5px 4px 3px rgba(0, 0, 0, .7);
}
</style>
